import {getWindowPendo} from './domHelper';
import PendoUtil from './pendo';
export interface IInitializeParams<IVisitorType , IAccountType> {
    visitor?: IVisitorType;
    account?: IAccountType;
}

export interface IPendoWrapper {
    initialize<T, K>(initializeArgs: IInitializeParams<T , K>): void;
    init(
        resourceUrl: string,
        apiKey: string,
        resourceName: string
    ): Promise<boolean>;
}

class PendoWrapper implements IPendoWrapper {
    init = (
        resourceUrl: string,
        apiKey: string,
        resourceName: string
    ): Promise<boolean> => {
        const scriptUrl: string = `${resourceUrl}/${apiKey}/${resourceName}`;
        return new Promise<boolean>((resolve, reject) =>
            PendoUtil.appendPendoScript(
                scriptUrl,
                () => resolve(true),
                () => reject(false)
            )
        );
    }

    initialize<VisitorType , AccountType>(initializeArgs?: IInitializeParams<VisitorType , AccountType>) {
        getWindowPendo().pendo?.initialize(initializeArgs);
    }
}

export default new PendoWrapper();
