import uuid from 'uuid';

function get(key) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return undefined;
  }
}

function set(key, value) {
  try {
    return localStorage.setItem(key, value);
  } catch (error) {
    return undefined;
  }
}

function getMachineId() {
  const key = 'machineId';
  const id = get(key) || uuid.v4();
  set(key, id);

  return id;
}

export default {
  id: getMachineId(),
};
