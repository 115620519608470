export const register = () => {
  if ('serviceWorker' in navigator && 'register' in navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js').then((reg) => {
      reg.onupdatefound = () => {
        const sw = reg.installing;
        sw.onstatechange = () => {
          // updated content is now cached
          if (sw.state === 'installed' && navigator.serviceWorker.controller) {
            location.reload();
          }
        };
      };
    });
  }
};

export const unregisterAll = () => {
  if ('serviceWorker' in navigator && 'getRegistrations' in navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
};
