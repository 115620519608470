import { initialize, LDClient, LDOptions, LDUser } from 'launchdarkly-js-client-sdk';
import defaultLDValues from '../config/launchDarklyDefaultValues';
import { filterObj } from '../utils/object';
export { LDUser, LDOptions };
import logger from './LoggerService';

export type GetAllLaunchDarklyConfig = ILaunchDarklyConfigItem[];
export interface ILaunchDarklyConfigItem  {
  clientSideId: string;
  whiteListedFlags?: string[];
  ldUser?: any;
  options?: LDOptions;
}

export type LaunchDarklyReponse = {
  [key: string]: any;
} | {};

export const defaultLDOptions = { useReport: true, sendEvents: false };
let ldClient: LDClient | undefined;

const init = async (clientId: string, ldUser: any, options?: LDOptions): Promise<LDClient> => {
  const newLdClient = initialize(clientId, {
    key: ldUser.userKey,
    email: ldUser.userEmail,
    privateAttributeNames: ['email'],
    custom: ldUser.userData,
  }, options);
  await newLdClient.waitForInitialization();

  ldClient = newLdClient;

  return ldClient;
};

export const launchDarklyClient = async (
  clientId: string,
  ldUser?: LDUser,
  options: LDOptions = defaultLDOptions
): Promise<LDClient> => {
  const user = { ...ldUser, anonymous: true };
  const newLdClient = await init(clientId, user, { ...defaultLDOptions, ...options });
  await newLdClient.waitUntilReady();

  return newLdClient;
};

export const getAllLaunchDarklyFlags =
async <T>(clientId: string, ldUser?: any, options?: LDOptions): Promise<T | {}> => {
  try {
    const client = await launchDarklyClient(clientId, ldUser, options);
    return client.allFlags() as T;
  } catch {
    logger.error('Launch Darkly flags fetch failed');
    throw {};
  }
};

export const getMultipleLaunchDarklyFlags = async <T>(
    config: GetAllLaunchDarklyConfig,
    defaultValue?: LaunchDarklyReponse
  ): Promise<T | {}> => {
  try {
     const result = await Promise.all(config.map((configItem: ILaunchDarklyConfigItem) => {
          const {clientSideId, ldUser, options} = configItem;
          return getAllLaunchDarklyFlags(clientSideId, ldUser, options);
     }));
     return result.reduce((combinedFlags: object, individualResult: object, index) => {
      const configItem: ILaunchDarklyConfigItem = config[index];
      const whiteListed = configItem?.whiteListedFlags;
      if (whiteListed && whiteListed.length) {
          const filteredReponse =  filterObj(individualResult)(whiteListed);
          return {
            ...combinedFlags,
            ...filteredReponse,
          };
        } else {
          return {
            ...combinedFlags,
            ...individualResult,
          };
        }
     }, {});
  } catch (error) {
      return defaultValue || defaultLDValues;
    // fail silently
  }
};
