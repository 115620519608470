import { GotoLogger, IGotoLoggerMessage, LogCategory, LoggingService, LoggingServiceConfig } from '@getgo/goto-logger';
import platform from 'platform';
import config from '../appConfig';
import machine from '../utils/machine';

let loggingService: LoggingService | null = null;

// it is not recommended to send debug logs to Splunk to save resources
const allowedLogCategories: readonly LogCategory[] = ['error', 'warn', 'info', 'log'];

const createRootLogger = () => {
  const logger = GotoLogger.createRoot({ mincategory: 'debug' });

  if (!['dev', 'test'].includes(process.env.ENVIRONMENT)) {
    loggingService = initializeLoggingService();
    allowedLogCategories.forEach((logCategory) => {
      const logHandler = (message: IGotoLoggerMessage) => {
        loggingService.log(message);
      };
      logger.subscribe(logCategory, logHandler);
    });
  }
  return logger;
};

const initializeLoggingService = () => {
  const lsConfig: Partial<LoggingServiceConfig> = {
    loggingUrl: config.logging.url,
    fixed: {
      ls_source: config.logging.source,
      product: config.name,
      env: config.env,
      machineId: machine.id,
    },
  };

  const lsService = new LoggingService(lsConfig);
  lsService.field('loggingId', Date.now());
  lsService.field('browser', {
    'name': platform.name,
    'version': platform.version,
    'os.family': platform.os.family,
    'os.version': platform.os.version,
    'language': navigator.language,
  });

  return lsService;
};

const rootLogger = createRootLogger();
export default rootLogger;
