
export function clearSessionStorage(key) {
  sessionStorage.removeItem(key);
}

export function getSessionStorage(key) {
  return sessionStorage.getItem(key);
}

export function setSessionStorage(key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.log('session storage is not supported by browser');
  }
}
