export const fetchJson = <T>(url: string, options: any = {}) => (
  fetch(url, options).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    } else if (res.status === 204) {
      return undefined;
    }

    return res.json() as Promise<T>;
  })
);

export const fetchJsonforSummary = <T>(url: string, options: any = {}) => (
  fetch(url, options).then(async (res) => {
    if (!res.ok) {
      const errorData = await res.json();
      throw new Error(`${JSON.stringify(errorData)}`);
    } else if (res.status === 204) {
      return undefined;
    }

    return res.json() as Promise<T>;
  })
);

export const fetchJsonWithAuth = <T>(url: string, token: { access_token: string }, options: any = {}) => {
  const headers = options.headers || {};

  return (
    fetchJson<T>(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    })
  );
};

export const fetchJsonWithAuthSummary = <T>(url: string, token: { access_token: string }, options: any = {}) => {
  const headers = options.headers || {};

  return (
    fetchJsonforSummary<T>(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    })
  );
};
