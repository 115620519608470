import {getSessionStorage} from '../utils/sessionStorage';
import {setupElasticSearch} from '../utils/setupElasticSearch';

export interface IRoute {
  name: 'session' | 'share' | '';
  path: string;
  params: {
    [key: string]: string;
  };
}

const getParam = (hash: string) => hash.substr(hash.lastIndexOf('/') + 1);

export const getCurrentRoute = (path = location.hash): IRoute => {
  const route = path.toLowerCase().substring(0, path.lastIndexOf('/') + 1);
  setupElasticSearch(path);

  switch (route) {
    case '#/sessionid/':
      return { name: 'session', path, params: { sessionKey: getParam(path) } };
    case '#/sharekey/':
      return { name: 'share', path, params: { shareKey: getParam(path) } };
    case '#/s/':
      return { name: 'share', path, params: { shareKey: getParam(path) } };
    default:
      return { name: '', path, params: {} };
  }
};

export const getShareUrl = (shareKey: string) =>
  `${location.protocol}//${location.host}${location.pathname}#/s/${shareKey}`;

// Generate share URL pointing to base html file
export const getBaseShareUrl = (shareKey: string) => {
  const g2cUrl = getSessionStorage('g2cDomainUrl');
  return g2cUrl ? `${g2cUrl}${shareKey}` : `${location.protocol}//${location.host}/#/s/${shareKey}`;
};

// TODO: needed for OOS share panel. waiting for confirmation
export const getBaseShareUrlForOOS = (sessionKey: string) => {
  const domainUrl = getSessionStorage('g2cDomainUrl');
  return `${domainUrl}/${sessionKey}`;
};
