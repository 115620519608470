/**
 * Type-safe access of deep property of an object. *
 * @param obj                   Object to get deep property
 * @param unsafeDataOperation   Function that returns the deep property
 * @param valueIfFail           Value to return in case if property not found
 */
export const get = <O, T>(obj: O, unsafeDataOperation: (x: O) => T, valueIfFail?: any): T => {
  try {
    return unsafeDataOperation(obj);
  } catch (error) {
    return valueIfFail;
  }
};

/**
 * Meant to clean the stringified object before sending to logging, such as splunk.
 * Will return original object if failed.
 * @param obj an object that will get stringified and have quotes removed from string
 */
export const JSONStringifyClean = <O>(obj: O) => {
  try {
    return JSON.stringify(obj).replace(/"/g, '');
  } catch (e) {
    return obj;
  }
};

export const filterObj = <O>(obj: O) => (keyArray: string[]) => {
  try {
    if (keyArray && !keyArray?.length) {
      return obj;
    }
    return Object.keys(obj).reduce((objectAcc, objectKey) => {
      if (keyArray.includes(objectKey)) {
        return {
          ...objectAcc,
          [objectKey]: obj[objectKey],
        };
      } else {
        return objectAcc;
      }
    }, {});
  } catch (e) {
    return obj;
  }
};
