import mixpanel from 'mixpanel-browser';
import { IMeResponse } from '../api/insightsService/IMeResponse';
import {getSessionStorage} from './sessionStorage';

export function track(...args: Parameters<typeof mixpanel.track>) {
  let argumentsWithContainerKey = {};
  try {
    const container = getSessionStorage('container');
    if (container) {
      argumentsWithContainerKey = {
        ...args[1],
        container,
      };
      args[1] = argumentsWithContainerKey;
    }
  } catch (e) {
    // fail silently
  }
  mixpanel.track(...args);
}

export function init(token: string) {
  mixpanel.init(token);
}

export function registerUser(shareKey: string, transcriptEnabled: boolean, user?: IMeResponse, container?: string) {
  mixpanel.register({shareKey, transcriptEnabled, container});

  if (user) {
    mixpanel.register({
      distinct_id: user.userKey,
      userKey: user.userKey,
      accountKey: user.accountKey,
      container,
    });

    mixpanel.identify(user.userKey);
    mixpanel.people.set({
      $email: user.email,
    });
  }
}
