import { IAnalyticsResponse } from '../../api/insightsService/IAnalyticsResponse';
import { IMeResponse } from '../../api/insightsService/IMeResponse';

export const getVisiorId = (userData: IMeResponse) => {
    return userData?.userKey || '';
};

export const getAccountId = (userData: IMeResponse) => {
    return userData?.accountKey || '';
};

export const extractVisitorData = (userData: IMeResponse, analyticsData: IAnalyticsResponse) => {
    return {
        id: getVisiorId(userData),
        ...extractVisitorMetaData(userData, analyticsData),
    };
};

export const extractVisitorMetaData = (userData: IMeResponse, analyticsData: IAnalyticsResponse) => {
    const isMeetingOwner = !!userData && userData.userKey === analyticsData.userKey;
    return {
        isMeetingOwner,
    };
};

export const extractAccountData = (user: IMeResponse) => {
    return {
        id: getAccountId(user),
    };
};

export const extractPendoInitializeData = (userData: IMeResponse, analyticsData: IAnalyticsResponse) => {
     return {
          visitor: extractVisitorData(userData, analyticsData),
          account: extractAccountData(userData),
     };
};
