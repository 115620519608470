import {getWindowPendo} from './domHelper';

export enum PendoFunctions {
    initialize = 'initialize',
    identify = 'identify',
    updateOptions = 'updateOptions',
    pageLoad = 'pageLoad',
    track = 'track',
}
export interface IPendoFunctionCallRecord {
    _q: any;
}
export type Pendo = { [K in PendoFunctions]: (...args: ReadonlyArray<unknown>) => void } & IPendoFunctionCallRecord;
interface IPendoUtil {
    appendPendoScript(scriptURL: string, onLoad: () => void, onError: () => void): void;
}
class PendoUtil implements IPendoUtil {

    appendPendoScript = (scriptURL: string, onLoad: () => void, onError: () => void) => {
        const pendoLib = getWindowPendo().pendo || ({} as Pendo);
        getWindowPendo().pendo = pendoLib;
        pendoLib._q = pendoLib._q || [];
        for (const func of Object.values(PendoFunctions)) {
            if (!pendoLib[func]) {
                pendoLib[func] = (...args: unknown[]) => pendoLib._q.push([func, [...args]]);
            }
        }
        const script = getWindowPendo().document.createElement('script');
        script.async = true;
        script.onload = onLoad;
        script.onerror = onError;
        script.src = scriptURL;
        getWindowPendo().document.body.appendChild(script);

    }
}

export default new PendoUtil();
