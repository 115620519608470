import { mergeDeep } from './utils/merge';

// process.env.ENVIRONMENT is used in SPA & window.environment is used in OOS
// on local, process.env.ENVIRONMENT is 'undefined' for SPA & window.environment is undefined for OOS
const environment = process.env.ENVIRONMENT ?? window.environment;

const defaults = {
  env: environment,
  pinglessJwplayerEnv: 'ed',
  name: 'g2m-interactive-recording',
  feedbackSurvey: {
    urls: {
      user: {
        1: 'https://logmein.co1.qualtrics.com/jfe/form/SV_9WZbFVISRtBa149',
        10: 'https://logmein.co1.qualtrics.com/jfe/form/SV_eFfiFOaHsDIXxU9',
        anytime: 'https://logmein.co1.qualtrics.com/jfe/form/SV_9SIqwOuth1SwVgh',
      },
      g2c: 'https://logmeinvoc.iad1.qualtrics.com/jfe/form/SV_3QaxnrXNOoK5JTD',
      summaryFeedback: 'https://logmein.co1.qualtrics.com/SE/',
      SummaryFeedbackSurveyId: 'SV_85F44icdNvAIRYW',
    },
  },
  g2m: {
    url: 'https://globaled1.g2m.test.expertcity.com/',
  },
  g2mPasService: {
    url: 'https://g2m-apied.services.g2m.test.expertcity.com',
  },
  insightsService: {
    url: 'https://apied1.insights.g2m.test.expertcity.com',
  },
  ivsService: {
    url: 'https://iamed1.serversdev.getgo.com',
  },
  learnMoreSummaryLink: {
    url: 'https://support.goto.com/meeting/help/meeting-summaries',
  },
  meetingService: {
    url: 'https://apiglobaled1.g2m.test.expertcity.com/rest/2',
  },
  notesService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com',
  },
  socialGraphService : {
    url: 'https://socialgraph.serversdev.getgo.com',
  },
  searchOrchestrationService: {
    url: 'https://apied-int-searchorchestrationservice.services-ed.goto.com',
  },
  calenarIntegrationService: {
    url: 'https://calendar-integration.services-ed.goto.com',
  },
  summaryService: {
    url: 'https://apied-int-summaryservice.services-ed.goto.com',
  },
  AssetBaseURL: {
    url: 'https://apied-int-summaryservice.services-ed.goto.com/summaryservice',
  },
  createAssetSummaryService: {
    url: 'https://apied.assets.lmiinc.test.expertcity.com',
  },
  intercom: {
    appId: 'u98gqxzt',
  },
  launchDarkly: {
    clientSideId: '5aaab91a171e7c2a95c3a6db',
  },
  launchDarklyME: {
    clientSideId: '5f4e64055ab5eb092299af9c',
  },
  mixpanel: {
    token: 'd2eca1ec6d277880d8852d3fe4d5b653',
  },
  googleAnalytics: {
    trackingId: 'UA-114305258-1',
  },
  oauth: {
    url: 'https://authenticationed1.col.test.expertcity.com',
    clientId: '136caa76-f4d6-4693-bb78-06fe0ecdd43f',
  },
  logging: {
    source: 'logmein.g2m.transcripts',
    url: 'https://logginged1.getgo.test.expertcity.com',
  },
  telemetry: {
    baseUrl: 'https://lb0-telemetry-ed.dev.citrixsaassbe.net',
    inSessionInterval: 10000,
    namespace: 'logmein.g2m.transcripts',
  },
  transcriptErrorTimeout: 86400000,
  urls: {
    aboutUs: 'https://www.logmeininc.com/about-us',
    adminUrl: 'https://admin.logmeininc.com',
    faqUrl: 'https://support.logmeininc.com/gotomeeting/insights/faq',
    g2tUrl: 'https://global.gototraining.com',
    g2wUrl: 'https://global.gotowebinar.com',
    meetingFrontEndSvcUrl: 'https://global.gotomeeting.com',
    myaccountUrl: 'https://cmrc.logmeininc.com',
    ovUrl: 'https://global.openvoice.com',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    setsaleUrl: 'https://analytics.gotomeeting.com',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
    dummySession: '/dummyData/session.json',
    transcriptsPage: 'https://transcriptsed.g2m.test.expertcity.com',
  },
  featureToggleFlags: {
    app: {
      editButton: false,
      talkTime: true,
      copyTranscript: true,
      expiration: false,
      oneTimeFlow: false,
    },
    shared: {
      editButton: false,
      talkTime: true,
      copyTranscript: true,
    },
  },
  machineLearningModels: {
    actionItems: {
      name: 'Action_Items',
      version: '1.9.19.5',
    },
    highlights: {
      name: 'Highlight',
      version: '0.1.0.0',
    },
    newActionItems: {
      name: 'action_item',
      version: '1.0.0.0',
    },
  },
  pendo: {
    apiKey: 'f1830314-8b16-409a-5e13-8626e3847ae1',
    resourceUrl: 'https://content.ipd.goto.com/agent/static',
    resourceName: 'pendo.js',
  },
};

if (
  process.env.ENVIRONMENT === 'dev' ||
  process.env.ENVIRONMENT === 'test' ||
  process.env.ENVIRONMENT === 'undefined' ||
  window.environment === 'dev'
) {
  mergeDeep(defaults, {
    env: 'development',
    telemetry: {
      baseUrl: 'https://lb0-telemetry-ed.dev.citrixsaassbe.net',
      inSessionInterval: 10000,
      namespace: 'logmein.g2m.transcripts',
    },
    featureToggleFlags: {
      app: {
        editButton: true,
        talkTime: true,
        copyTranscript: true,
        shareExpiration: true,
        oneTimeFlow: true,
      },
      shared: {
        editButton: false,
        talkTime: true,
        copyTranscript: true,
      },
    },
    urls: {
      adminUrl: 'https://admined1.lmiinc.test.expertcity.com',
      g2tUrl: 'https://globaled1.g2t.test.expertcity.com',
      g2wUrl: 'https://globaled1.gotowebinar.com',
      meetingFrontEndSvcUrl: 'https://globaled1.g2m.test.expertcity.com',
      myaccountUrl: 'https://secureed1.col.test.expertcity.com',
      ovUrl: 'https://globaled1.openvoice.com',
      setsaleUrl: 'https://analyticsed1.g2m.test.expertcity.com',
      dummySession: '/dummyData/session.json',
      transcriptsPage: 'https://transcriptsed.g2m.test.expertcity.com',
    },
  });
}

if (process.env.ENVIRONMENT === 'ed' || window.environment === 'dev') {
  mergeDeep(defaults, {
    g2m: {
      url: 'https://globaled1.g2m.test.expertcity.com/',
    },
    featureToggleFlags: {
      app: {
        editButton: true,
        talkTime: true,
        copyTranscript: true,
        shareExpiration: true,
        oneTimeFlow: true,
      },
      shared: {
        editButton: false,
        talkTime: true,
        copyTranscript: true,
      },
    },
    g2mPasService: {
      url: 'https://g2m-apied.services.g2m.test.expertcity.com',
    },
    insightsService: {
      url: 'https://apied1.insights.g2m.test.expertcity.com',
    },
    ivsService: {
      url: 'https://iamed1.serversdev.getgo.com',
    },
    meetingService: {
      url: 'https://apiglobaled1.g2m.test.expertcity.com/rest/2',
    },
    notesService: {
      url: 'https://apied-featureservices.devplatform-dev.expertcity.com',
    },
    searchOrchestrationService: {
      url: 'https://apied-int-searchorchestrationservice.services-ed.goto.com',
    },
    calenarIntegrationService: {
      url: 'https://calendar-integration.services-ed.goto.com',
    },
    summaryService: {
      url: 'https://apied-int-summaryservice.services-ed.goto.com',
    },
    AssetBaseURL: {
      url: 'https://apied-int-summaryservice.services-ed.goto.com/summaryservice',
    },
    createAssetSummaryService: {
      url: 'https://apied.assets.lmiinc.test.expertcity.com',
    },
    logging: {
      url: 'https://logginged1.getgo.test.expertcity.com',
    },
    oauth: {
      url: 'https://authenticationed1.col.test.expertcity.com',
      clientId: '136caa76-f4d6-4693-bb78-06fe0ecdd43f',
    },
    telemetry: {
      baseUrl: 'https://lb0-telemetry-ed.dev.citrixsaassbe.net',
      inSessionInterval: 10000,
      namespace: 'logmein.g2m.transcripts',
    },
    urls: {
      adminUrl: 'https://admined1.lmiinc.test.expertcity.com',
      g2tUrl: 'https://globaled1.g2t.test.expertcity.com',
      g2wUrl: 'https://globaled1.gotowebinar.com',
      meetingFrontEndSvcUrl: 'https://globaled1.g2m.test.expertcity.com',
      myaccountUrl: 'https://secureed1.col.test.expertcity.com',
      ovUrl: 'https://globaled1.openvoice.com',
      setsaleUrl: 'https://analyticsed1.g2m.test.expertcity.com',
      transcriptsPage: 'https://transcriptsed.g2m.test.expertcity.com',
    },
  });
}

if (process.env.ENVIRONMENT === 'rc' || window.environment === 'rc') {
  mergeDeep(defaults, {
    pinglessJwplayerEnv: 'rc',
    g2m: {
      url: 'https://globalrc1.g2m.test.expertcity.com/',
    },
    g2mPasService: {
      url: 'https://g2m-apirc.services.g2m.test.expertcity.com',
    },
    insightsService: {
      url: 'https://apirc1.insights.g2m.test.expertcity.com',
    },
    ivsService: {
      url: 'https://iamrc1.serversdev.getgo.com',
    },
    meetingService: {
      url: 'https://apiglobalrc1.g2m.test.expertcity.com/rest/2',
    },
    notesService: {
      url: 'https://apirc-featureservices.devplatform-dev.expertcity.com',
    },
    socialGraphService : {
      url: 'https://socialgraphrc.serversdev.getgo.com',
    },
    searchOrchestrationService: {
      url: 'https://apirc-int-searchorchestrationservice.services-rc.goto.com',
    },
    calenarIntegrationService: {
      url: 'https://calendar-integration.services-rc.goto.com',
    },
    summaryService: {
      url: 'https://apirc-int-summaryservice.services-rc.goto.com',
    },
    AssetBaseURL: {
      url: 'https://apirc-int-summaryservice.services-rc.goto.com/summaryservice',
    },
    createAssetSummaryService: {
      url: 'https://apirc.assets.lmiinc.test.expertcity.com',
    },
    logging: {
      url: 'https://loggingrc1.getgo.test.expertcity.com',
    },
    oauth: {
      url: 'https://loginrc2.lmiinc.test.expertcity.com/oauth',
      clientId: '63266a8f-8012-4702-8c8e-3866eb454014',
    },
    telemetry: {
      baseUrl: 'https://lb0-telemetry-rc.dev.citrixsaassbe.net',
      inSessionInterval: 10000,
      namespace: 'logmein.g2m.transcripts',
    },
    launchDarkly: {
      clientSideId: '5b16f33028156f319e66ba08',
    },
    urls: {
      adminUrl: 'https://adminrc1.lmiinc.test.expertcity.com',
      g2tUrl: 'https://globalrc1.g2t.test.expertcity.com',
      g2wUrl: 'https://globalrc1.gotowebinar.com',
      meetingFrontEndSvcUrl: 'https://globalrc1.g2m.test.expertcity.com',
      myaccountUrl: 'https://securerc1.col.test.expertcity.com',
      ovUrl: 'https://globalrc1.openvoice.com',
      setsaleUrl: 'https://analyticsrc1.g2m.test.expertcity.com',
      transcriptsPage: 'https://transcriptsrc.g2m.test.expertcity.com',
    },
  });
}

if (process.env.ENVIRONMENT === 'stage' ||
    window.environment === 'staging') {
  mergeDeep(defaults, {
    pinglessJwplayerEnv: 'stage',
    g2m: {
      url: 'https://globalstage.gotomeeting.com',
    },
    g2mPasService: {
      url: 'https://g2m-apistage.services.gotomeeting.com',
    },
    insightsService: {
      url: 'https://apistage.insights.gotomeeting.com',
    },
    ivsService: {
      url: 'https://iamstage.servers.getgo.com',
    },
    meetingService: {
      url: 'https://apiglobalstage.gotomeeting.com/rest/2',
    },
    notesService: {
      url: 'https://apistage.services.gotomeeting.com',
    },
    socialGraphService : {
      url: 'https://socialgraphstage.servers.getgo.com',
    },
    searchOrchestrationService: {
      url: 'https://apistage-searchorchestrationservice.services-stage.goto.com',
    },
    calenarIntegrationService: {
      url: 'https://calendar-integration.services-stage.goto.com',
    },
    summaryService: {
      url: 'https://apistage-int-summaryservice.services-stage.goto.com',
    },
    AssetBaseURL: {
      url: 'https://apistage-int-summaryservice.services-stage.goto.com/summaryservice',
    },
    createAssetSummaryService: {
      url: 'https://apistage.assets.logmeininc.com',
    },
    logging: {
      url: 'https://loggingstage.getgo.com',
    },
    oauth: {
      url: 'https://authenticationstage.logmeininc.com/oauth',
      clientId: 'd680c4be-ad0c-44f2-977e-8021f69d83f4',
    },
    telemetry: {
      baseUrl: 'https://telemetrystage.servers.getgo.com',
      inSessionInterval: 10000,
      namespace: 'logmein.g2m.transcripts',
    },
    launchDarkly: {
      clientSideId: '5b16f33b88a2b83198680027',
    },
    launchDarklyME: {
      clientSideId: '5f4e64055ab5eb092299af9d',
    },
    urls: {
      adminUrl: 'https://adminstage.logmeininc.com',
      g2tUrl: 'https://globalstage.gototraining.com',
      g2wUrl: 'https://globalstage.gotowebinar.com',
      meetingFrontEndSvcUrl: 'https://globalstage.gotomeeting.com',
      myaccountUrl: 'https://cmrcstage.logmeininc.com',
      ovUrl: 'https://globalstage.openvoice.com',
      setsaleUrl: 'https://analyticsstage.gotomeeting.com',
      transcriptsPage: 'https://transcriptsstage.g2m.test.expertcity.com',
    },
  });
}

if (process.env.ENVIRONMENT === 'live' ||
    window.environment === 'prod') {
  mergeDeep(defaults, {
    env: 'production',
    pinglessJwplayerEnv: 'live',
    g2m: {
      url: 'https://global.gotomeeting.com',
    },
    g2mPasService: {
      url: 'https://g2m-api.services.gotomeeting.com',
    },
    insightsService: {
      url: 'https://api.insights.gotomeeting.com',
    },
    ivsService: {
      url: 'https://iam.servers.getgo.com',
    },
    meetingService: {
      url: 'https://apiglobal.gotomeeting.com/rest/2',
    },
    notesService: {
      url: 'https://api.services.gotomeeting.com',
    },
    socialGraphService : {
      url: 'https://socialgraph.servers.getgo.com',
    },
    searchOrchestrationService: {
      url: 'https://api-searchorchestrationservice.services.goto.com',
    },
    calenarIntegrationService: {
      url: 'https://calendar-integration.services.goto.com',
    },
    summaryService: {
      url: 'https://api-summaryservice.services.goto.com',
    },
    AssetBaseURL: {
      url: 'https://api-summaryservice.services.goto.com/summaryservice',
    },
    createAssetSummaryService: {
      url: 'https://api.assets.logmeininc.com',
    },
    logging: {
      url: 'https://logging.getgo.com',
    },
    launchDarkly: {
      clientSideId: '5aaab91a171e7c2a95c3a6dc',
    },
    launchDarklyME: {
      clientSideId: '5f4e64055ab5eb092299af9d',
    },
    mixpanel: {
      token: 'c73c1059c67c323751ca7a4e3dd1fcc8',
    },
    googleAnalytics: {
      trackingId: 'UA-114268689-1',
    },
    oauth: {
      url: 'https://authentication.logmeininc.com/oauth',
      clientId: 'aaa0f0c2-c655-4401-a6d1-0885b1401d57',
    },
    telemetry: {
      baseUrl: 'https://telemetry.servers.getgo.com',
      inSessionInterval: 10000,
      namespace: 'logmein.g2m.transcripts',
    },
    urls: {
      aboutUs: 'https://www.logmeininc.com/about-us',
      adminUrl: 'https://admin.logmeininc.com',
      faqUrl: 'https://support.logmeininc.com/gotomeeting/insights/faq',
      g2tUrl: 'https://global.gototraining.com',
      g2wUrl: 'https://global.gotowebinar.com',
      meetingFrontEndSvcUrl: 'https://global.gotomeeting.com',
      myaccountUrl: 'https://cmrc.logmeininc.com',
      ovUrl: 'https://global.openvoice.com',
      privacy: 'https://www.logmeininc.com/legal/privacy',
      setsaleUrl: 'https://analytics.gotomeeting.com',
      terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
      transcriptsPage: 'https://transcripts.gotomeeting.com',
    },
  });
}

const config = defaults; // for easier auto import

export default config;
