import Auth, { Token } from '@getgo/auth-client';
import config from '../appConfig';

const SESSION_KEY = 'g2mTranscriptsToken';
const redirectUrl = `${location.protocol}//${location.host}/authenticate.html`;

const authClient = new Auth({
  client_id: config.oauth.clientId,
  url: config.oauth.url,
  redirect_url: redirectUrl,
});

/** Returns the login URL needed for React-Navbar */
export const getLoginUrl = () => (
  authClient.login({
    onLoginRedirect: (url) => url,
    state: { path: encodeURIComponent(location.href) },
  })
);

/** Gets an existing token, if not expired, from either the path or localStorage */
export const getTokenIfValid = () => {
  let json: object;
  try {
    const state = localStorage.getItem(SESSION_KEY);
    json = state ? JSON.parse(state) : undefined;
  } catch (err) {
    // ignore
  }
  return authClient.init(json);
};

export const checkSSO = () => {
  let checkedSSO: boolean;
  try {
    checkedSSO = Boolean(sessionStorage.getItem('checkedSSO'));
  } catch (err) {
    // ignore
  }

  if (!checkedSSO) {
    sessionStorage.setItem('checkedSSO', 'true');
    loginWithSSO();
    return true;
  }

  return false;
};

export const setToken = (token: Token) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(token));
  return token;
};

export const clearToken = () => localStorage.clear();

export const login = () => authClient.login({
  state: { path: encodeURIComponent(location.href)},
});

export const loginWithSSO = () => authClient.loginWithSSO({state: { path: encodeURIComponent(location.href)}});

export const logout = () => {
  clearToken();
  authClient.logout({
    redirect_url: `${location.protocol}//${location.host}`,
  });
};
