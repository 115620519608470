import * as api from './api/insightsService';
import { IShareResponse } from './api/insightsService/IShareResponse';
import { IIvsResponse } from './api/IVSService/IIvsResponse';
import config from './appConfig';
import * as authService from './services/AuthenticationService';
import { getMultipleLaunchDarklyFlags, LaunchDarklyReponse } from './services/FeatureService';
import * as IVSTokenService from './services/IVSTokenService';
import { getCurrentRoute, getShareUrl, IRoute } from './services/RouteService';
import { identify as appCuesIdentify, init as initAppCues } from './utils/appcues';
import { init as initGoogleAnalytics } from './utils/GoogleAnalytics';
import { init as initMixpanel, registerUser as registerUserMixpanel, track } from './utils/mixpanel';
import { initialize as initPendo } from './utils/pendo/pendoHelpers';
import { getIsRebrandingEnabled } from './utils/rebranding';
import { unregisterAll } from './utils/serviceWorker';
import {clearSessionStorage} from './utils/sessionStorage';

initGoogleAnalytics(config.googleAnalytics.trackingId);
initMixpanel(config.mixpanel.token);
initAppCues();

if (process.env.NODE_ENV !== 'development') {
  unregisterAll();
}

const renderError = async (token, isRebrandingEnabled, userPromise?) => {
  const [userData, error] = await Promise.all([
    userPromise,
    import(/* webpackChunkName: "error" */'./error'),
  ]);

  return error.init(token, isRebrandingEnabled, userData);
};

export const renderUnauthorized = async (token, userPromise, flags: LaunchDarklyReponse) => {
  const [userData, unauthorized] = await Promise.all([
    userPromise,
    import(/* webpackChunkName: "unauthorized" */'./unauthorized'),
  ]);

  return unauthorized.init(token, userData, flags);
};

const renderOneTimeCodeFlow = async (userPromise, flags: LaunchDarklyReponse, ivsToken?: IIvsResponse, token?) => {
  track('Started One Time Code Flow');
  const [userData, oneTimeCodeFlow] = await Promise.all([
    userPromise,
    import(/* webpackChunkName: "one-time-auth" */'./one-time-auth'),
  ]);

  oneTimeCodeFlow.init(userData, flags, ivsToken, token);
};

const routeSession = async ({ params }: IRoute, token, userPromise, flags: LaunchDarklyReponse) => {
  if (!token) {
    return authService.login();
  }

  let share: IShareResponse;
  try {
    share = await api.getShareFromSession(params.sessionKey, token);
  } catch (err) {
    try {
      const userData = await userPromise;
      share = await api.createShare(params.sessionKey, userData, token, undefined, [], []);
    } catch (err) {
      return renderUnauthorized(token, userPromise, flags);
    }
  }

  return location.assign(getShareUrl(share.shareKey));
};

const routeShare = async ({ params }: IRoute,
                          token, userPromise, ivsToken: IIvsResponse, flags: LaunchDarklyReponse) => {
  try {
    const [data, userData, app] = await Promise.all([
      api.getShareData(params.shareKey, token, ivsToken),
      userPromise,
      import(/* webpackChunkName: "app" */'./app'),
    ]);

    const isMeetingOwner = !!userData && userData.userKey === data.userKey;
    registerUserMixpanel(data.shareKey, data.transcriptEnabled, userData);
    initPendo(config.pendo)(userData, data);
    appCuesIdentify(userData, { isMeetingOwner });
    track('Visited Transcripts Page');
    return app.init(token, userData, data, ivsToken, flags);
  } catch (err) {
    const errorCode = err.toString();
    if (errorCode.includes('403') || errorCode.includes('401')) {
       return renderOneTimeCodeFlow(userPromise, flags, ivsToken, token);
    } else {
        return renderUnauthorized(token, userPromise, flags);
    }
  }
};

const routeFallthrough = (route: IRoute) => {
  return location.assign(config.g2m.url);
};

const getMe = async (token, isRebrandingEnabled) => {
  try {
    return await api.getMe(token);
  } catch (err) {
    if (err.message === 'Unauthorized') {
      authService.login();
    } else {
      renderError(token, isRebrandingEnabled);
      throw err;
    }
  }
};

export const init = async () => {
  const launchDarklyAnonymousUserDetails = {
    userKey: '',
    userEmail: '',
    userData: {
      accountKey: '',
    },
  };
  const flags =  await getMultipleLaunchDarklyFlags<LaunchDarklyReponse>([{
    clientSideId: config.launchDarkly.clientSideId,
    ldUser: launchDarklyAnonymousUserDetails,
  }, {
    clientSideId: config.launchDarklyME.clientSideId,
    ldUser: launchDarklyAnonymousUserDetails,
    whiteListedFlags: ['notesVersion'],
  }]);
  const route = getCurrentRoute();
  if (route.name === '') {
    return routeFallthrough(route);
  }

  const token = await authService.getTokenIfValid();
  const ivsToken = IVSTokenService.getToken();
  if (!token) {
    if (authService.checkSSO()) {
      return;
    }
  }

  const userPromise = token ? getMe(token, getIsRebrandingEnabled(flags)) : Promise.resolve(undefined);
  clearSessionStorage('g2cDomainUrl'); // clear the url if session is not loaded under G2C

  if (route.name === 'session') {
    return routeSession(route, token, userPromise, flags);
  }

  return routeShare(route, token, userPromise, ivsToken, flags);
};

window.onhashchange = init;

if (process.env.NODE_ENV !== 'test') {
  init();
}
