export const ga = (...args: any[]) => {
  (window as any).ga(...args);
};

/**
 * Tracks a JavaScript error with optional fields object overrides.
 * This function is exported so it can be used in other parts of the codebase.
 * E.g.:
 *
 *    `fetch('/api.json').catch(trackError);`
 *
 * @param {(Error|Object)=} err
 * @param {Object=} fieldsObj
 */
export const trackError = (err = {} as Error, fieldsObj = {}) => {
  ga('send', 'event', {
    eventCategory: 'Error',
    eventAction: err.name || '(no error name)',
    eventLabel: `${err.message}\n${err.stack || '(no stack trace)'}`,
    nonInteraction: true,
    ...fieldsObj,
  });
};

/**
 * Tracks any errors that may have occured on the page prior to analytics being
 * initialized, then adds an event handler to track future errors.
 */
const trackErrors = () => {
  // Errors that have occurred prior to this script running are stored on
  // `window.__e.q`, as specified in `index.html`.
  const loadErrorEvents = (window as any).__e && (window as any).__e.q || [];

  const trackErrorEvent = (event: any) => {
    // Use a different eventCategory for uncaught errors.
    const fieldsObj = { eventCategory: 'Uncaught Error' };

    // Some browsers don't have an error property, so we fake it.
    const err = event.error || {
      message: `${event.message} (${event.lineno}:${event.colno})`,
    };

    trackError(err, fieldsObj);
  };

  // Replay any stored load error events.
  for (let event of loadErrorEvents) { // tslint:disable-line
    trackErrorEvent(event);
  }

  // Add a new listener to track event immediately.
  window.addEventListener('error', trackErrorEvent);
};

/**
 * Creates the trackers and sets the default transport and tracking
 * version fields. In non-production environments it also logs hits.
 */
const createTracker = (trackingId: string) => {
  ga('create', trackingId, 'auto');
  ga('set', 'transport', 'beacon');
};

const sendInitialPageview = () => {
  ga('send', 'pageview');
};

export const init = (trackingId: string) => {
  (window as any).ga = (window as any).ga || ((...args: any[]) => ((ga as any).q = (ga as any).q || []).push(args));

  createTracker(trackingId);
  trackErrors();
  sendInitialPageview();
};
