import { IAnalyticsResponse } from '../../api/insightsService/IAnalyticsResponse';
import { IMeResponse } from '../../api/insightsService/IMeResponse';
import PendoUtil from './pendoBridge';
import { extractPendoInitializeData } from './pendoSelectors';

export interface IPendoScriptConfig {
  resourceUrl: string;
  apiKey: string;
  resourceName: string;
}

export interface IVisitorPendo {
    id: string;
    isMeetingOwner: boolean;
}

export interface IAccountPendo {
    id: string;
}

export const initialize = (config: IPendoScriptConfig) =>
async (user?: IMeResponse, analyticsData?: IAnalyticsResponse) => {
    try {
      let isPendoLoaded: boolean = false;
      try {
        isPendoLoaded = await PendoUtil.init(
          config.resourceUrl,
          config.apiKey,
          config.resourceName
        );
      } catch (err) {
        // fail silenlty
      }
      if (isPendoLoaded) {
        if (user) {
          PendoUtil.initialize<IVisitorPendo, IAccountPendo>(extractPendoInitializeData(user, analyticsData));
        } else {
          PendoUtil.initialize();
        }
      }
    } catch {
      // fail silenlty
    }
  };
