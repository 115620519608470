import { IIvsResponse } from '../api/IVSService/IIvsResponse';

const SESSION_KEY = 'g2mIVSToken';

export const getToken = () => {
  let json: IIvsResponse;
  try {
    const state = sessionStorage.getItem(SESSION_KEY);
    json = state ? JSON.parse(state) : undefined;
  } catch (err) {
    // ignore
  }
  return json;
};

export const setToken = (token: IIvsResponse) => {
  sessionStorage.setItem(SESSION_KEY, JSON.stringify(token));
  return token;
};

export const clearToken = () => {
  sessionStorage.removeItem(SESSION_KEY);
};
