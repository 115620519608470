import { LaunchDarklyReponse } from '../services/FeatureService';

export const getIsRebrandingEnabled = (flags?: LaunchDarklyReponse) => {
    return true;
};

export const getThemeProviderParams = () => {
    return   {
      rebranding2021: true,
      skin: 'gotoapp',
    } as any;
};
